<template>
  <div class="card profile-card border-0">
      <div class="card-body">
        <div class="group-profile text-center">
            <img v-if="user.foto" :src="user.foto" :alt="user.pas_nama">
            <img v-else src="@/assets/user-placeholder.png" :alt="user.pas_nama">
            <span class="d-block fs-18 fw-700 color-neutral-900 mt-2">{{ user.pas_nama }}</span>
            <span class="d-block fs-12 fw-400 color-secondary-500 mt-1">{{ user.email_user }}</span>
            <div class="d-inline-flex align-items-center profil-badge mt-2">
              <img src="@/assets/icon/badge.svg" alt="ikon badge">
              <span class="fs-12 fw-400 text-white ms-1">Member Biasa</span>
            </div>
        </div>
        <div class="group-menu d-flex flex-column d-none d-md-block">
          <router-link
            :to="{ name: 'ProfilOrder' }"
            class="d-flex justify-content-between align-items-center">
            <div class=" d-flex align-items-center py-2">
                <i class="ri-profile-line ri-lg px-1"></i>
                <div
                class="item-menu ps-3 menu-title text-placeholder text-nunito text-placeholder fw-bold fs-14"
                :class="{ active: isActive('order') }">Pesanan</div>
            </div>
          </router-link>
          <router-link
            :to="{ name: 'Konsultasi' }"
            class="d-flex justify-content-between align-items-center">
            <div class=" d-flex align-items-center py-2">
              <i class="ri-message-3-line ri-lg px-1"></i>
              <div
              class="item-menu ps-3 menu-title text-placeholder text-nunito text-placeholder fw-bold fs-14"
              :class="{ active: isActive('consultation') }">Konsultasi</div>
            </div>
          </router-link>
          <router-link
            :to="{ name: 'Notifications' }"
            class="d-flex justify-content-between align-items-center">
            <div class=" d-flex align-items-center py-2">
              <i class="ri-notification-2-line ri-lg px-1"></i>
              <div
                class="item-menu ps-3 menu-title text-placeholder text-nunito text-placeholder fw-bold fs-14"
                :class="{ active: isActive('notification') }">
                Notifikasi
              </div>
            </div>
            <div>
              <span class="badge bg-error-disable text-error">{{ notificationCount }}</span>
            </div>
          </router-link>
          <router-link
            :to="{ name: 'Profil' }"
            class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center py-2">
              <i class="ri-settings-3-line ri-lg px-1"></i>
              <div
                class="item-menu ps-3 menu-title text-placeholder text-nunito text-placeholder fw-bold fs-14"
                :class="{ active: isActive('setting') }">
                Pengaturan
              </div>
            </div>
          </router-link>
        </div>
      </div>
  </div>
</template>

<script>
import { user } from '@/core/auth'
import { getNotifications, notificationCount } from '@/core/notifications'
import { onMounted } from '@vue/runtime-core'

export default {
  setup () {
    onMounted(() => {
      getNotifications()
    })

    return {
      user,
      notificationCount
    }
  },
  data () {
    return {
      sidebarItem: 'order'
    }
  },
  methods: {
    isActive (menuItem) {
      return this.sidebarItem === menuItem
    },
    setActive (menuItem) {
      this.sidebarItem = menuItem
    }
  }
}
</script>

<style lang="scss" scoped>
.group-profile {
  img {
      width: 84px;
      height: 84px;
      object-fit: cover;
      border-radius: 100px;
  }
}

.item-menu.active {
  color: #007A98;
}

.profil-badge {
  background: #005F82;
  border-radius: 100px;
  padding: 4px 8px 4px 4px;

  img {
    width: 20px;
    height: 20px;
  }
}
</style>
