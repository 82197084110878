<template>
    <div id="order-history">
        <div class="container">
            <div class="row pt-3">
                <div class="col-md-3">
                    <card-profile />
                </div>
                <div class="col-md-9">
                    <div class="row">
                        <div class="col-md-7">
                            <div class="page-title d-flex justify-content-between align-items-center pb-2">
                                <div class="bd-highlight d-flex align-items-center">
                                    <i class="ri-arrow-left-line pe-4"></i>
                                    <div class="text-title-active text-poppins fw-600 fs-16">Riwayat Pesanan</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="history-group">
                                <div class="card card-order bg-white my-3">
                                    <div class="card-body bg-transparent d-flex align-items-center justify-content-between py-2">
                                        <div class="bd-hightlight d-flex flex-column">
                                            <div class="fm-poppins text-title-active fw-500 fs-16">Umam Fajri</div>
                                            <div class="fm-nunito text-body-light fw-600 fs-14">#090729</div>
                                        </div>
                                        <div class="bd-highlight">
                                            <div class="fm-nunito text-body-light fw-600 fs-14">13/09/2020</div>
                                        </div>
                                    </div>
                                    <div class="card-footer d-flex justify-content-between align-items-center bg-transparent py-3">
                                        <div class="bd-highlight d-flex flex-column">
                                            <div class="bd-highlight">
                                                <div class="total fm-nunito fw-500 fs-12 text-body-light">Total</div>
                                            </div>
                                            <div class="bd-highlight">
                                                <div class="fm-nunito fw-700 fs-18 text-body">Rp. 350.000</div>
                                            </div>
                                        </div>
                                        <div class="bd-highlight">
                                            <div class="card-location border-0 p-3 bd-highlight d-flex flex-row align-items-center justify-content-between">
                                            <div class="bd-highlight d-flex flex-column">
                                                <p class="text-body fm-poppins fst-normal fw-500 fs-14 lh-14 mb-2">Parahita Klinik Gresik</p>
                                                <p class="text-body-light fm-nunito fw-600 fs-12 mb-0">Ruko Plaza Sidoarjo Blok B2-3, JI. MH. Thamrin 2</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card card-order bg-white my-3">
                                    <div class="card-body bg-transparent d-flex align-items-center justify-content-between py-2">
                                        <div class="bd-hightlight d-flex flex-column">
                                            <div class="fm-poppins text-title-active fw-500 fs-16">Umam Fajri</div>
                                            <div class="fm-nunito text-body-light fw-600 fs-14">#090729</div>
                                        </div>
                                        <div class="bd-highlight">
                                            <div class="fm-nunito text-body-light fw-600 fs-14">13/09/2020</div>
                                        </div>
                                    </div>
                                    <div class="card-footer d-flex justify-content-between align-items-center bg-transparent py-3">
                                        <div class="bd-highlight d-flex flex-column">
                                            <div class="bd-highlight">
                                                <div class="total fm-nunito fw-500 fs-12 text-body-light">Total</div>
                                            </div>
                                            <div class="bd-highlight">
                                                <div class="fm-nunito fw-700 fs-18 text-body">Rp. 350.000</div>
                                            </div>
                                        </div>
                                        <div class="bd-highlight">
                                            <div class="card-location border-0 p-3 bd-highlight d-flex flex-row align-items-center justify-content-between">
                                            <div class="bd-highlight d-flex flex-column">
                                                <p class="text-body fm-poppins fst-normal fw-500 fs-14 lh-14 mb-2">Parahita Klinik Gresik</p>
                                                <p class="text-body-light fm-nunito fw-600 fs-12 mb-0">Ruko Plaza Sidoarjo Blok B2-3, JI. MH. Thamrin 2</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CardProfile from '@/components/profile/CardProfile'

export default {
  components: {
    CardProfile
  }
}
</script>

<style scoped>
.card-order {
    border: 1px solid #EEEFF7;
    box-sizing: border-box;
    border-radius: 10px;
}

.card-location {
    border: 1px solid #EFF0F7;
    box-sizing: border-box;
    border-radius: 10px;
    background: #F7F7FC;
}
</style>
